(<template>
  <div class="other-services">
    <div v-if="!successSubmit">
      <h3 class="other-services__title">{{ $gettext('Book other service') }}</h3>
      <post-section :class="{'in-progress': progress}"
                    :title="contactInfoSectionTitle"
                    class="is-progress-bar other-services__section">
        <div class="other-services__inputs-wrapper">
          <sk-input :placeholder="nameText"
                    :preselected-value="name"
                    :description="nameText"
                    :validation-name="errors.firstName.name"
                    :class="'input-border-radius'"
                    class="other-services__input post-form__field--half-width"
                    @fieldvaluechanged="setName" />
          <sk-input :placeholder="surnameText"
                    :preselected-value="surname"
                    :description="surnameText"
                    :validation-name="errors.secondName.name"
                    :class="'input-border-radius'"
                    class="other-services__input post-form__field--half-width"
                    @fieldvaluechanged="setSurname" />
        </div>
        <sk-input :placeholder="emailText"
                  :preselected-value="email"
                  :description="emailText"
                  :type="'email'"
                  :validation-name="errors.email.name"
                  :class="'input-border-radius'"
                  class="other-services__input"
                  @fieldvaluechanged="setEmail" />
        <div class="other-services__inputs-wrapper">
          <sk-select :type="'countryCodes'"
                     :description="countryCodeText"
                     :default-value="countryCodeText"
                     :preselected-value="phoneCode"
                     :mob-numeric-keyboard="true"
                     :validation-name="errors.phoneCode.name"
                     :class="'input-border-radius'"
                     class="other-services__county-code"
                     @csvaluechanged="setPhoneCode" />
          <sk-input :description="phoneNumberText"
                    :preselected-value="phoneNumber"
                    :margin-bottom="true"
                    :placeholder="phoneNumberText"
                    :autofocus="true"
                    :type="'tel'"
                    :reg-exp="/[^0-9]/g"
                    :mob-numeric-keyboard="true"
                    :validation-name="errors.phoneNumber.name"
                    :class="'input-border-radius'"
                    @fieldvaluechanged="setPhoneNumber" />
        </div>
      </post-section>
      <post-section :class="{'in-progress': progress}"
                    :title="serviceSectionTitle"
                    class="is-progress-bar other-services__section">
        <div class="other-services__radio-wrapper">
          <sk-radio v-for="service in servicesList"
                    :key="service.value"
                    :item-text="service.text"
                    :item-value="service.value"
                    :preselected-value="choosenService"
                    class="other-services__radio"
                    @radiochanged="catchServices" />
        </div>
        <sk-input v-if="choosenService === 'other'"
                  :placeholder="otherServiceText"
                  :preselected-value="otherService"
                  :no-description="true"
                  :validation-name="errors.otherService.name"
                  :class="'input-border-radius'"
                  class="other-services__input other-service-input"
                  @fieldvaluechanged="setOtherService" />
        <div class="other-services__date-wrapper">
          <sk-datepicker :preselected-value="date"
                         :full-calendar="false"
                         :default-value="datePickerDefaultValue"
                         :prevent-call="true"
                         :icon="true"
                         :description="dateText"
                         class="other-services__datepicker input-border-radius"
                         @changeday="catchDeadline" />
          <sk-timepicker v-if="date"
                         :preselected-value="time"
                         :start-select-from="startTimeBegining"
                         :auto-scroll="true"
                         :default-value="'--:--'"
                         :icon="true"
                         :description="timeSelectText"
                         :time-step="$timepickerStep()"
                         :mob-numeric-keyboard="true"
                         :items-list="availableStartTime"
                         class="other-services__timepicker input-border-radius"
                         @timepickervaluechanged="catchStartTime" />
          <button v-if="date"
                  class="other-services__reset-btn"
                  @click="emptyDeadline"></button>
        </div>
        <div class="other-services__description-textarea">
          <span>{{ descriptionInfoText }}</span>
          <span>{{ descriptionInfoAdditionalText }}</span>
        </div>
        <sk-textarea :preselected-value="descriptionInfo"
                     :placeholder="descriptionInfoPlaceholder"
                     :validation-name="errors.description.name"
                     :max-length="500"
                     class="other-services__textarea input-border-radius"
                     @fieldvaluechanged="setDescriptionInfo" />
        <button class="sk-btn sk-btn--primary other-services__booking-btn is-progress-bar"
                @click="submit">{{ $gettext('Book') }}</button>
      </post-section>
    </div>
    <section v-else
             class="other-services__success success-block">
      <div class="icon-success"></div>
      <div class="success-block__body">
        <h3>{{ successTitle }}</h3>
        <p class="success-block__description">{{ successMessage }}</p>
      </div>
      <post-section :title="successSubtitle"
                    class="success-block__info info-lines other-services__section">
        <div class="info-lines__item">
          <span class="info-lines__item--name">{{ $gettext('Name') }}</span>
          <span class="info-lines__item--value">{{ sendedName }}</span>
        </div>
        <div class="info-lines__item">
          <span class="info-lines__item--name">{{ $gettext('Email') }}</span>
          <span class="info-lines__item--value">{{ email }}</span>
        </div>
        <div class="info-lines__item">
          <span class="info-lines__item--name">{{ $gettext('Phone') }}</span>
          <span class="info-lines__item--value">{{ sendedPhoneNumber }}</span>
        </div>
        <hr class="info-lines__line">
        <div class="info-lines__item">
          <span class="info-lines__item--name">{{ $gettext('Service') }}</span>
          <span class="info-lines__item--value">{{ sendedService }}</span>
        </div>
        <div class="info-lines__item">
          <span class="info-lines__item--name">{{ $gettext('Date') }}</span>
          <span class="info-lines__item--value">{{ sendedDate }}</span>
        </div>
        <div v-if="choosenService === 'other'"
             class="info-lines__item">
          <span class="info-lines__item--name">{{ $gettext('Description') }}</span>
          <span class="info-lines__item--value">{{ descriptionInfo }}</span>
        </div>
      </post-section>
    </section>
  </div>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';
  import PostSection from '@/components/booking_components/PostSection';
  import helpers from '@/helpers';

  export default {
    asyncData({store}) {
      let promisesArr = '';

      if (!store.state.OtherServicesStore.services) {
        promisesArr = [...promisesArr, store.dispatch('OtherServicesStore/getOtherServices')];
      }

      return promisesArr ? Promise.all(promisesArr) : '';
    },
    components: {
      'post-section': PostSection,
    },
    data() {
      return {
        progress: false,
        name: '',
        surname: '',
        email: '',
        phoneCode: '',
        phoneNumber: '',
        descriptionInfo: '',
        choosenService: 1,
        date: '',
        time: '',
        otherService: '',
        successSubmit: false,
        errors: {
          firstName: {name: 'firstNameError', errors: []},
          secondName: {name: 'secondNameError', errors: []},
          email: {name: 'emailError', errors: []},
          phoneCode: {name: 'phoneCodeError', errors: []},
          phoneNumber: {name: 'phoneNumberError', errors: []},
          otherService: {name: 'otherServiceError', errors: []},
          description: {name: 'descriptionError', errors: []}
        }
      };
    },
    computed: {
      ...mapState('OtherServicesStore', {
        services: (state) => state.services || [],
      }),
      contactInfoSectionTitle() { return this.$gettext('Contact information'); },
      serviceSectionTitle() { return this.$gettext('Service'); },
      nameText() { return this.$gettext('First name'); },
      surnameText() { return this.$gettext('Last name'); },
      emailText() { return this.$gettext('Email'); },
      countryCodeText() { return this.$gettext('Country code'); },
      phoneNumberText() { return this.$gettext('Phone number'); },
      otherServiceText() { return this.$gettext('Other'); },
      descriptionInfoPlaceholder() { return this.$gettext('Describe what you need...'); },
      datePickerDefaultValue() { return this.$gettext('Flexible'); },
      dateText() { return this.$gettext('Date'); },
      timeSelectText() { return this.$gettext('Time'); },
      descriptionInfoText() { return this.$gettext('Description'); },
      descriptionInfoAdditionalText() { return this.$gettext('Optional'); },
      blockedStartDatesArray() { return []; },
      startTimeBegining() { return this.date === this.$moment().format('YYYY-MM-DD') ? helpers.dateTime.convertCurrentTime(this.$moment) : ''; },
      successTitle() { return this.$gettext('Request received'); },
      successMessage() { return this.$gettext('Thank you for reaching out to us! We received your request and our team is working to address your needs. You\'ll receive the update soon.'); },
      successSubtitle() { return this.$gettext('Request details'); },
      currentAssignmentType() {
        return this.$route.params.type === 'other' ? 'other' : 1;
      },
      availableStartTime() {},
      servicesList() {
        const servicesArray = this.services.map((item) => ({
          value: item.id,
          text: item.name
        }));

        servicesArray.push({
          value: 'other',
          text: this.otherText
        });

        return servicesArray;
      },
      otherText() { return this.$gettext('Other'); },
      sendedName() { return `${this.name} ${this.surname}`; },
      sendedPhoneNumber() { return this.phoneCode + this.phoneNumber; },
      sendedService() {
        if (this.choosenService === 'other') {
          return this.otherService;
        }
        const sendedServiceName = this.servicesList.find((service) => service.value === this.choosenService);
        return sendedServiceName.text;
      },
      sendedDate() {
        return this.date
          ? `${this.date}${this.time ? `, ${this.time}` : ''}`
          : this.$gettext('Flexible');
      },
      currentLang() { return this.$language.current; }
    },
    watch: {
      currentLang() {
        this.$store.dispatch('OtherServicesStore/getOtherServices');
      }
    },
    methods: {
      ...mapMutations('OtherServicesStore', ['clearStore']),
      setName(value) { this.name = value; },
      setSurname(value) { this.surname = value; },
      setEmail(value) { this.email = value; },
      setPhoneCode(value) { this.phoneCode = value; },
      setPhoneNumber(value) { this.phoneNumber = value; },
      setDescriptionInfo(value) { this.descriptionInfo = value; },
      catchDeadline(value) { this.date = value; },
      catchStartTime(value) { this.time = value; },
      catchServices(value) { this.choosenService = value; },
      setOtherService(value) { this.otherService = value; },
      transformDateTime(date, time) {
        return this.$moment(`${date} ${time}`, 'YYYY-MM-DDHH:mm').toISOString();
      },
      removeErrors() {
        for (const key of Object.keys(this.errors)) {
          this.errors[key].errors = [];
        }
      },
      emptyDeadline() {
        this.date = '';
        this.time = '';
      },
      resetForm() {
        this.successSubmit = false;
        this.name = '';
        this.surname = '';
        this.email = '';
        this.phoneCode = '';
        this.phoneNumber = '';
        this.descriptionInfo = '';
        this.date = '';
        this.time = '';
        this.otherService = '';
      },
      validateForm() {
        let isValidForm = true;

        this.removeErrors();

        if (!this.name.trim()) {
          isValidForm = false;
          this.errors.firstName.errors.push(this.$gettext('First name is required'));
        }

        if (!this.surname.trim()) {
          isValidForm = false;
          this.errors.secondName.errors.push(this.$gettext('Second name is required'));
        }

        if (!this.email.trim()) {
          isValidForm = false;
          this.errors.email.errors.push(this.$gettext('Email is required'));
        }

        if (!this.phoneCode.trim()) {
          isValidForm = false;
          this.errors.phoneCode.errors.push(this.$gettext('Country code is required'));
        }

        if (!this.phoneNumber.trim()) {
          isValidForm = false;
          this.errors.phoneNumber.errors.push(this.$gettext('Phone number is required'));
        }

        if (this.choosenService === 'other' && !this.otherService.trim()) {
          isValidForm = false;
          this.errors.otherService.errors.push(this.$gettext('Other service is required'));
        }

        if (this.choosenService === 'other' && !this.descriptionInfo.trim()) {
          isValidForm = false;
          this.errors.description.errors.push(this.$gettext('Description for other service is required'));
        }

        this.$store.commit('ErrorsStore/setError', {name: this.errors.firstName.name, errors: this.errors.firstName.errors});
        this.$store.commit('ErrorsStore/setError', {name: this.errors.secondName.name, errors: this.errors.secondName.errors});
        this.$store.commit('ErrorsStore/setError', {name: this.errors.email.name, errors: this.errors.email.errors});
        this.$store.commit('ErrorsStore/setError', {name: this.errors.phoneCode.name, errors: this.errors.phoneCode.errors});
        this.$store.commit('ErrorsStore/setError', {name: this.errors.phoneNumber.name, errors: this.errors.phoneNumber.errors});
        this.$store.commit('ErrorsStore/setError', {name: this.errors.otherService.name, errors: this.errors.otherService.errors});
        this.$store.commit('ErrorsStore/setError', {name: this.errors.description.name, errors: this.errors.description.errors});
        this.$scrollToErrors();

        return isValidForm;
      },
      submit() {
        if (this.validateForm()) {
          const form = new FormData();
          form.append('json_body[firstName]', this.name.trim());
          form.append('json_body[lastName]', this.surname.trim());
          form.append('json_body[email]', this.email.trim());
          form.append('json_body[phoneCode]', this.phoneCode.trim());
          form.append('json_body[phoneNumber]', this.phoneNumber.trim());
          if (this.date) {
            form.append('json_body[startTime]', this.transformDateTime(this.date, this.time));
          }
          if (this.choosenService === 'other') {
            form.append('json_body[otherService]', this.otherService.trim());
          }
          if (this.choosenService != 'other') {
            form.append('json_body[serviceId]', Number(this.choosenService));
          }
          if (this.descriptionInfo.trim()) {
            form.append('json_body[description]', this.descriptionInfo);
          }

          this.progress = true;

          this.$store.dispatch('OtherServicesStore/createOtherServicesBooking', form).then(() => {
            this.progress = false;
            this.successSubmit = true;
            this.$router.push({query: {...this.$route.query, success: true}});
          }).catch((error) => {
            const errors = error?.data?.errors;
            this.progress = false;
            if (errors?.email) {
              this.errors.email.errors.push(errors.email);
              this.$store.commit('ErrorsStore/setError', {name: this.errors.email.name, errors: this.errors.email.errors});
            }
            if (errors?.phone_number) {
              this.errors.phoneNumber.errors.push(errors.phone_number);
              this.$store.commit('ErrorsStore/setError', {name: this.errors.phoneNumber.name, errors: this.errors.phoneNumber.errors});
            }
            if (errors?.description) {
              this.errors.description.errors.push(errors.description);
              this.$store.commit('ErrorsStore/setError', {name: this.errors.description.name, errors: this.errors.description.errors});
            }
            this.$scrollToErrors();
          });
        }
      }
    },
    mounted() {
      this.choosenService = this.$route.params.service === 'other' ? 'other' : 1;
    },
    destroyed() {
      this.clearStore();
      this.successSubmit = false;
    },
    beforeRouteUpdate(to, from, next) {
      if (from.query.success) {
        this.successSubmit = false;
        this.choosenService = this.$route.params.service;
        this.resetForm();
      }
      next();
    },
  };
</script>

<style>
.other-services {
  width: 100%;
  max-width: 640px;
  margin: 20px auto 0;
}

.other-services__title {
  margin: 24px 0;
  color: #333;
  font-size: 24px;
}

.other-services__section .post-section__container {
  padding: 16px 24px;
}

.other-services__inputs-wrapper {
  display: flex;
  justify-content: space-between;
}

.other-services__radio-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 40px;
}

.other-services__radio .sk-radio__imitation {
  box-shadow: 0 0 0 1px #a4a9bc;
}

.other-services__radio .sk-radio__input:checked + .sk-radio__imitation {
  background-color: #000;
}

.other-services__radio {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.other-service-input {
  margin: 16px 0 -5px 0;
}

.other-services__date-wrapper {
  display: flex;
  gap: 16px;
  margin: 24px 0;
}

.other-services__datepicker {
  width: 100%;
  max-width: 200px;
}

.other-services__timepicker {
  width: 100%;
  max-width: 130px;
}

.other-services__reset-btn {
  flex: 0 0 20px;
  align-self: flex-end;
  height: 25px;
  margin-bottom: 5px;
  background-image: url(~@assets/imgs/undefined_imgs/reset_icon.svg);
  background-position: 50% 50%;
  background-size: 17px auto;
  background-repeat: no-repeat;
  cursor: pointer;
}

.deadline-section__reset-btn:focus {
  outline: var(--default-focus-outline);
}

.other-services__description-textarea {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  color: #333;
  font-size: 12px;
}

.other-services__textarea {
  height: 120px;
}

.input-border-radius .sk-input__input-wrapper .sk-input__input,
.input-border-radius .sk-input__input-wrapper--textarea .sk-input__textarea,
.input-border-radius .sk-select,
.input-border-radius .sk-date-picker,
.input-border-radius .sk-time-picker {
  border-radius: 4px;
}

.other-services__county-code {
  width: 140px;
  margin-right: 16px;
}

.other-services__booking-btn {
  max-width: 85px;
  margin-left: auto;
}

.success-block__description {
  margin-top: 8px;
}

.icon-success {
  display: block;
  width: 64px;
  height: 64px;
  margin-bottom: 10px;
  background-image: url(~@assets/imgs/undefined_imgs/success_icon_big.svg);
  background-repeat: no-repeat;
}

.info-lines__item {
  display: flex;
  margin-bottom: 16px;
}

.info-lines__line {
  height: 1px;
  margin: 24px 0;
  border: none;
  background-color: #e4e6eb;
}

.info-lines__item--name {
  flex-basis: 30%;
  color: #666;
}

.info-lines__item--value {
  flex-basis: 70%;
}

@media (max-width: 767px) {
  .other-services__title {
    margin: 16px;
  }

  .other-services__radio-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .other-services__datepicker {
    max-width: 140px;
  }

  .other-services__timepicker {
    max-width: 110px;
  }

  .other-services__section .post-section__container {
    padding: 16px !important;

    /* should be removed after fix */
    border-radius: 0 0 8px 8px !important;
  }

  .icon-success,
  .success-block__body {
    margin-left: 16px;
  }

  .success-block {
    padding-top: 24px;
    border-radius: 8px;
    background-color: #fff;
  }
}
</style>
